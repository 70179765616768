:root {
    --concrete: rgb(255, 255, 255);
    --thunder: rgba(46, 46, 46, 1);
  
    --font-size-l2: 30px;
    --font-size-m2: 25px;
    --font-size-s: 22px;
    --font-size-xl2: 39px;
    --font-size-xs: 18px;
    --font-size-xxl2: 40px;
    --font-size-xxxl2: 74px;
  
    --font-family-montserrat: "Montserrat", Helvetica;
  }
  .montserrat-medium-thunder-18px {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
  }
  .montserrat-normal-thunder-22px {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
  }
  .montserrat-normal-thunder-25px {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m2);
    font-style: normal;
    font-weight: 400;
  }
  .montserrat-semi-bold-thunder-40px {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl2);
    font-style: normal;
    font-weight: 600;
  }
  .montserrat-semi-bold-thunder-25px {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m2);
    font-style: normal;
    font-weight: 600;
  }
  .montserrat-semi-bold-thunder-74px {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxl2);
    font-style: normal;
    font-weight: 600;
  }