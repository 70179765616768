.abando {
  margin-left: 0px;
}

.overlap-group1 {
  background-color: black;
  box-shadow: 6px 6px 18px #00000029;
  height: 180px;
  position: relative;
  width: auto;
  border-radius: 10px;
  color: white;
  margin-top: 20px;
}
.ciclo-1 {
  left: 20px;
  letter-spacing: 0;
  line-height: 60px;
  position: relative;
  top: 8px;
  white-space: nowrap;
  font-weight: bold;

}
.concierto {
  margin-right: 15px;
}
.contenido {
  align-items: flex-start;
  display: flex;
  margin-bottom: 150px;
}
.descripcin {
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
}
.descripcin-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 1008px;
  width: 722px;
}
.flex-col {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
}
.flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 120px;
}
.la-cantante-y-compos {
  color: #707070;
  font-family: var(--font-family-montserrat);
  font-weight: 300;
  line-height: 40px;
  width: auto;
  margin-left: 5px;
  color: white;
}
.tags-1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 6px 6px 18px #00000029;
  display: flex;
  margin-top: 20px;
  width: 380px;
}
.overlap-groupInfo {
  align-items: center;
  background-color: var(--white);
  box-shadow: -6px -6px 6px #ffffff29;
  border-radius: 10px;
  display: flex;
  height: 180px;
  width: 380px;
  padding: 8px 20px;
}
.overlap-group1-1Even {
  align-items: flex-start;
  align-self: center;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 227px;
  justify-content: flex-end;
  margin-top: 41px;
  min-width: 460px;
  padding: 89.1px 213px;
}

.pop {
  margin-left: 24px;
}
.tags {
  letter-spacing: 0;
  line-height: 60px;
  min-height: 32px;
  white-space: nowrap;
  margin-top: auto;
  margin-right: auto;
  color: white;
}

.title {
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxxl);
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: 40px;
  position: relative;
  white-space: normal;
  color: white;
}
.text-3Even {
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 50px;
  width: 238px;
  font-size: 1.2rem;
  color: white;
}
.text-DirEven {
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 15px;
  line-height: 2;
  width: 287px;
  color: white;
}
.ubicacionEven {
  align-items: flex-start;
  background-color: black;
  border-radius: 10px;
  box-shadow: 6px 6px 18px #00000029;
  display: flex;
  height: max-content;
  /* margin-top: 60px; */
  width: 100%;
  color: white;
  font-size: 13.5PX;
  font-family: var(--font-family-montserrat);
}
.overlap-group4Even {
  
}

.ubicacinEven {
  letter-spacing: 0;
  line-height: 60px;
  margin-bottom: -28px;
  margin-left: 20px;
  min-height: 32px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.25rem;
}
.icon-material-location-onEven {
  height: 33px;
  object-fit: cover;
  width: 21px;
}
.texto-info{
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 60px;
}

.fondoMap{
  background-color:red;
  margin-top:20px;
  height:310px;
  width: fit-content;
}

#map{
  height: 280px;
  width: 440px;
  top: 15px;
  margin-left:10px;
  margin-right:10px;
}
.colmDer{
  margin-left: 50px;
}

.backgroundGallery{
  margin-top: 35px;
  width: 460px;
}

.buttonnormal{
  padding: 20px;
  justify-content: center;
  display: flex;
}

@media  (max-width:700px) {
  
  #map{
    width: 320px;
  }
  .colmDer{
    margin-left: 0px;
  }
  .backgroundGallery{
    width: 320px;
  }
  .buttonnormal{
    padding: 5px;
  }
}

