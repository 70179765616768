.acompaante-1 {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -36px;
    margin-top: 43px;
    min-height: 24px;
    white-space: nowrap;
  }
  .aviso-legal {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 76px;
    margin-top: 1.0px;
    min-height: 24px;
    min-width: 105px;
    white-space: nowrap;
  }
 
  .buttonnormalRes {
    min-width: auto;
    position: absolute;
    margin-top: inherit;
    border-radius: 20px;
  }
  .buttonnQrDowloand{
    border-radius: 10px;
    width: 200px;
    margin-top: 20px;
  }
  .info-evento {
    align-items: flex-start;
    align-self: flex-start;
    display: flex;
    margin-top: 35px;
    min-height: 188px;
  }
  .span1{
    margin: 0px;
    color: white;
  }
  .rectangle-1620 {
    background-color: black;
    border-radius: 10px;
    align-items: center;
    height: 47px;
    justify-content: center;
  }
  .entrada-1-de-2 {
    line-height: 50px;
    text-align: center;
    color: white;
  }
  .image-4 {
    object-fit: cover;
  }
  .texto-Confirmacion{
    margin-left: 40px;
    margin-top: 30px;
    width: 100%;
    margin-right: 30px;
    color: white;
  }
  .rectangle-1619 {
    background-color: black;
    border-radius: 10px;
    box-shadow: 6px 6px 18px #00000029;
    align-content: center;
    justify-content: center;
    margin: 15px;
    height: 200px;
    margin-top: 40px;
  }
  .overlap-group1-2Res{
    background-size: cover;
    height: 35px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
  }
  .entrada {
    align-items: flex-start;
    display: flex;
    height: max-content;
  }

  .overlap-group-entrada {
    height: 364px;
    position: relative;
    width: 400px;
    margin-top: 25px;
    
    align-items: center;
  }

  .text-enhorabuena {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-weight: 500;
    letter-spacing: 0.78px;
    line-height: 45px;
    margin-right: 8.0px;
    margin-top: 25px;
    min-height: max-content;
    word-wrap: normal;
    min-width: fit-content;
    text-align: center;
    white-space: normal;
    color: white;
  }
  .buttonnormal-1 {
    align-items: flex-start;
    display: flex;
    margin-right: 58.0px;
    margin-top: 61px;
    min-width: 266px;
  }
  .cancelar {
    left: 109px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
  }
  .checkbox-circle {
    align-items: flex-start;
    display: flex;
    margin-bottom: 9.0px;
    min-width: 21px;
  }
  .component-26-5 {
    align-items: flex-start;
    display: flex;
    height: 35px;
    justify-content: center;
    left: 109px;
    min-width: 233px;
    position: absolute;
    top: 865px;
  }
  .confirmacin {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 185px;
    min-height: 24px;
    white-space: nowrap;
  }
  .confirmar-correo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 76px;
    min-height: 59px;
    position: relative;
    width: 315px;
    color:white;
  }
  .correo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 59px;
    position: relative;
    width: 315px;
    color:white;
  }
  .correo-electronico {
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: -4px;
    margin-top: 5px;
    min-height: 21px;
    white-space: nowrap;
    color:white;
  }
  .datos {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 160px;
    min-height: 24px;
    min-width: 55px;
    white-space: nowrap;
  }
  .datos-acompaantes {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -36px;
    min-height: 24px;
    white-space: nowrap;
    color: white;
  }
  .datos-acompanante {
    align-items: flex-start;
    align-self: flex-start;
    display: flex;
    height: 177px;
    margin-left: 32px;
    margin-top: 52px;
    min-width: 580px;
    position: relative;
  }
  .dropdown {
    align-items: flex-start;
    display: flex;
    height: 38px;
    min-width: 62px;
  }

  .dropdown-entradas {
    align-items: flex-start;
    display: flex;
    height: 38px;
    min-width: 62px;
    position: absolute;
  }
  .ellipse-1 {
    border-radius: 13px;
    height: 26px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 26px;
  }
  .ellipse-2 {
    background-color: black;
    border-radius: 12px;
    height: 24px;
    left: 276px;
    position: absolute;
    top: 1px;
    width: 24px;
  }
  .ellipse-3 {
    background-color:black;
    border-radius: 12px;
    height: 24px;
    left: 552px;
    position: absolute;
    top: 1px;
    width: 24px;
  }
  .ellipse-412 {
    background-color: black;
    border-radius: 9.5px;
    height: 19px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 19px;
  }
  .fila-entrada{
    margin-left: auto;
  }
  .container-info{
  border-radius:10px;
  box-shadow: 6px 6px 18px #00000029;
  height: max-content;
  display: flex;
  flex-flow: wrap;
  margin-top: 40px;
  }
  .container-info2{
    border-radius:10px;
    box-shadow: 6px 6px 18px #00000029;
    height: max-content;
    display: flex;
    flex-flow: wrap;
    margin: 20px;
    padding: 15px;
    }
  .entrada-general {
    letter-spacing: 0;
    position: absolute;
    font-size: 1.10rem;
    color: white;
  }
  .Horaentrada-general{
    letter-spacing: 0;
    position: absolute;
    font-size: 1.10rem;
    font-weight: 400;
    color: white;
  }
  .entrada-general-1 {
    letter-spacing: 0;
    min-height: 24px;
    color: white;
  }
  .evento-2 {
    align-items: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    height: 2613px;
    overflow: hidden;
    padding: 54px 0;
    width: 1920px;
  }
  .flex-colRes {
    margin-left: 15px;
    width: 62px;
  }
  .flex-col-1Res {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    min-height: 354px;
    width: 148px;
  }
  .flex-col-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 129px;
    width: 200px;
  }
  .flex-col-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: -19px;
    min-height: 196px;
    width: 250px;
  }

  .flex-row {
    align-items: flex-end;
    display: flex;
    height: 129px;
    margin-top: -19px;
    min-width: 706px;
    position: relative;
  }
  .flex-row-1 {
    align-items: flex-start;
    display: flex;
    height: 57px;
    min-width: 1322px;
  }
  .flex-row-2 {
    align-items: flex-start;
    display: flex;
    height: 59px;
    margin-top: 52px;
    min-width: 706px;
  }
  .flex-row-3Res {
    align-items: flex-start;
    display: flex;
    height: 24px;
    margin-top: 2px;
    min-width: 702px;
  }
  .fondo {
    height: 678px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1923px;
  }
  .footer {
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    height: 300px;
    margin-top: 318px;
    min-width: 1920px;
    padding: 1.3px 0;
  }
  .group-555 {
    align-items: center;
    display: block;
    margin-top: auto;
    justify-content: center;
    display: flex;
  }
  .group-5552 {
    margin-top: 30px;
    width: 100%;
  }
  .icon-feather-check {
    height: 8px;
    left: 4px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 13px;
  }
  .icon-ionic-md-arrow-dropdown {
    height: 9px;
    left: 39px;
    object-fit: cover;
    position: absolute;
    top: 26px;
    width: 18px;
  }
  .info-entrada {
    align-items: flex-start;
    display: flex;
    justify-content: left;
  }
  .informacin {
    left: 1040px;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 728px;
    white-space: nowrap;
  }
  .line-1 {
    height: 3px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 11px;
    width: 554px;
  }
  .line-2 {
    height: 1px;
    object-fit: cover;
    width: 15px;
  }
  .line-3 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 15px;
  }
  .line-4 {
    height: 15px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .logo-ontourup-negro {
    align-self: flex-end;
    height: 53px;
    object-fit: cover;
    width: 117px;
  }
  .nombre {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 46px;
    min-height: 59px;
    position: relative;
    width: 250px;
    color:white;
  }
  .nombre-1 {
    letter-spacing: 0;
    line-height: 20px;
    min-height: 21px;
    white-space: nowrap;
    margin-top: 5px;
    width: 250px;
    color:white;
  }
  .nombre-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 46px;
    min-height: 59px;
    position: relative;
    width: 200px;
  }
  .number {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 14px;
    margin-top: -20.5px;
    min-height: 21px;
    min-width: 11px;
    white-space: nowrap;
  }
  .number-1 {
    left: 15px;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  .numero-telefono {
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: -1px;
    margin-top: -1px;
    min-height: 21px;
    white-space: nowrap;
  }
  .overlap-group {
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 295px;
    padding: 71.0px 298px;
    width: 1920px;
  }
  .overlap-group-1Res {
    height: 26px;
    position: relative;
    width: 576px;
  }
  .overlap-group-2 {
    height: 49px;
    position: relative;
  }
  .overlap-group-3 {
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 41px;
    justify-content: flex-end;
    min-width: 266px;
    padding: 5.5px 45px;
  }
  .texto-Reserva{
    margin-top: 80px;
    font-size: 20px;
  }
  .texto-entrada{
    text-align: center;
    color: white;
  }
  .texto-Error{
    text-align: center;
    margin-top: 35px;
    color: tomato;
  }
  
  .overlap-group-4 {
    height: auto;
    position: relative;
    width: 100%;
    background-color: black;
  }
  .overlap-group1Res {
    width: 100%;
    }
  .overlap-group2 {
    height: 99px;
    margin-top: -33px;
    position: relative;
    width: 299px;
  }
  .overlap-group2-1 {
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10.5px;
    height: 21px;
    position: relative;
    width: 21px;
  }
  .overlap-group2-2 {
    height: 15px;
    margin-bottom: 1.0px;
    margin-left: 12px;
    position: relative;
    width: 15px;
  }
  .overlap-group3Res {
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 35px;
    justify-content: flex-end;
    min-width: 233px;
    padding: 2.5px 69.5px;
  }
  .Seleccionar-Sesion{
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 35px;
    justify-content: flex-end;
  }
  .overlap-group4 {
    height: 246px;
    position: relative;
    width: 726px;
    
  }
  .overlap-group5 {
    height: 773px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1923px;
  }
  .overlap-group6 {
    height: 773px;
    margin-left: 0;
    margin-top: 29px;
    position: relative;
    width: 1923px;
  }
  .pago {
    align-items: flex-start;
    background-color: black;
    border-radius: 10px;
    box-shadow: 6px 6px 18px #00000029;
    margin-top: 40px;
    margin-left: auto;
    margin-bottom: 150px;
    margin-right: auto;
    width: 100%;
    max-width: 850px;
  }
  .path-3434 {
    height: 234px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 814px;
  }
  .path-3435 {
    height: 234px;
    left: 32px;
    object-fit: cover;
    position: absolute;
    top: 12px;
    width: 794px;
  }
  .persona-contacto {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 50.0px;
    margin-top: 55px;
    min-height: 221px;
    width: 706px;
    color:white;
  }
  .persona-de-contacto {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -36px;
    min-height: 24px;
    white-space: nowrap;
    color:white;
  }
  .place {
    letter-spacing: 0;
    position: absolute;
    white-space: nowrap;
    font-weight: 400;
    color: white;
  }
  .poltica-de-cookies {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 75px;
    min-height: 24px;
    min-width: 174px;
    white-space: nowrap;
  }
 
  .rectangle-1498 {
    background-color: black;
    border-radius: 10px;
    height: 33px;
    left: 34px;
    position: absolute;
    top: 34px;
    width: 231px;
  }
  .rectangle-1603 {
    border-radius: 10px;
    height: 87px;
    left: 12px;
    object-fit: cover;
    position: absolute;
    top: 12px;
    width: 287px;
  }
  .rectangle-1604 {
    border-radius: 10px;
    height: 89px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 287px;
  }
  .rectangle-168 {
    height: 950px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 445px;
  }
  .rectangle-172 {
    border-radius: 10px;
    height: 38px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 11px;
    width: 62px;
  }
  .rectangle-173 {
    background-color: black;
    border-radius: 10px;
    height: 36px;
    left: 1px;
    position: absolute;
    top: 12px;
    width: 60px;
  }
  .reserva-entradas {
    left: 1257px;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 728px;
    white-space: nowrap;
  }
  .reservar-entradas {
    color: var(--cararra);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs2);
    letter-spacing: 0.54px;
    line-height: 35px;
    min-height: 24px;
    min-width: 174px;
    text-align: center;
    white-space: nowrap;
    color: white;
  }
  .resumenRes {
    align-items: flex-start;
    display: flex;
    height: fit-content;
    left: 40px;
    min-width: fit-content;
    position: absolute;
    top: 15px;
  }
  .resumen-1 {
    -webkit-backdrop-filter: blur(30px) brightness(115%);
    align-items: center;
    backdrop-filter: blur(30px) brightness(115%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    left: 238px;
    min-height: 375px;
    padding: 10px 20px;
    position: absolute;
    top: 398px;
    width: 680px;
  }
  .resumen-2 {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -28px;
    min-height: 32px;
    white-space: nowrap;
  }
  .seleccin-entradas {
    letter-spacing: 0;
    line-height: 60px;
    min-height: 24px;
    min-width: 176px;
    white-space: nowrap;
  }
  .selector {
    align-items: center;
    align-self: center;
    display: flex;
    height: 19px;
    margin-right: 3.0px;
    margin-top: 29px;
    min-width: 68px;
  }
  .side-bar {
    align-items: flex-start;
    background-position: 50% 50%;
    background-size: auto;
    display: flex;
    width: 100%;
    height: 5px;
    margin-top: 90px;
  }
  .siguiente {
    letter-spacing: 0.54px;
    line-height: 18px;
    min-height: 24px;
    text-align: center;
    white-space: nowrap;
    font-size: 20px;
  }
  .span-1 {
    text-decoration: underline;
  }
  .stepper {
    width: 100%;
  }
  .steps {
    align-items: flex-end;
    background-color: black;
    display: flex;
    flex-direction: column;
    min-height: 115px;
    padding: 24px 58px;
    width: 820px;
  }
  .subtotal {
    letter-spacing: 0;
    white-space: nowrap;
  }
  .telefono {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.0px;
    margin-left: 31px;
    min-height: 59px;
    position: relative;
    width: 190px;
  }
  .text-1 {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 298px;
    margin-top: 1.0px;
    min-height: 24px;
    min-width: 199px;
    white-space: nowrap;
  }
  .text-13 {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 60px;
    min-height: 111px;
    min-width: 584px;
    text-align: center;
    width: 584px;
  }
  .text-14 {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -21px;
    margin-right: 55.0px;
    margin-top: 12px;
    min-height: 39px;
    min-width: 305px;
    white-space: nowrap;
  }
  .text-2 {
    letter-spacing: 0;
    margin-top: 15px;
    min-height: 24px;
    white-space: nowrap;
    color: white;
  }
  .text-3 {
    align-self: flex-end;
    height: 52px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: -2px;
    margin-left: 7px;
    min-height: 52px;
    min-width: 324px;
    width: 324px;
  }
  .text-4Res {
    letter-spacing: 0;
    position: absolute;
    margin-top: 110px;
    margin-left:30px;
    white-space: nowrap;
  }
  .text-9 {
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: -4px;
    margin-left: 0.43px;
    margin-top: -3px;
    min-height: 21px;
    white-space: nowrap;
  }
  .total {
    letter-spacing: 0;
    white-space: nowrap;
  }