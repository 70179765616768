@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,300,500,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,300,500,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --concrete: rgb(255, 255, 255);
    --thunder: rgba(46, 46, 46, 1);
  
    --font-size-l2: 30px;
    --font-size-m2: 25px;
    --font-size-s: 22px;
    --font-size-xl2: 39px;
    --font-size-xs: 18px;
    --font-size-xxl2: 40px;
    --font-size-xxxl2: 74px;
  
    --font-family-montserrat: "Montserrat", Helvetica;
  }
  .montserrat-medium-thunder-18px {
    color: rgba(46, 46, 46, 1);
    color: var(--thunder);
    font-family: "Montserrat", Helvetica;
    font-family: var(--font-family-montserrat);
    font-size: 18px;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
  }
  .montserrat-normal-thunder-22px {
    color: rgba(46, 46, 46, 1);
    color: var(--thunder);
    font-family: "Montserrat", Helvetica;
    font-family: var(--font-family-montserrat);
    font-size: 22px;
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
  }
  .montserrat-normal-thunder-25px {
    color: rgba(46, 46, 46, 1);
    color: var(--thunder);
    font-family: "Montserrat", Helvetica;
    font-family: var(--font-family-montserrat);
    font-size: 25px;
    font-size: var(--font-size-m2);
    font-style: normal;
    font-weight: 400;
  }
  .montserrat-semi-bold-thunder-40px {
    color: rgba(46, 46, 46, 1);
    color: var(--thunder);
    font-family: "Montserrat", Helvetica;
    font-family: var(--font-family-montserrat);
    font-size: 39px;
    font-size: var(--font-size-xl2);
    font-style: normal;
    font-weight: 600;
  }
  .montserrat-semi-bold-thunder-25px {
    color: rgba(46, 46, 46, 1);
    color: var(--thunder);
    font-family: "Montserrat", Helvetica;
    font-family: var(--font-family-montserrat);
    font-size: 25px;
    font-size: var(--font-size-m2);
    font-style: normal;
    font-weight: 600;
  }
  .montserrat-semi-bold-thunder-74px {
    color: rgba(46, 46, 46, 1);
    color: var(--thunder);
    font-family: "Montserrat", Helvetica;
    font-family: var(--font-family-montserrat);
    font-size: 74px;
    font-size: var(--font-size-xxxl2);
    font-style: normal;
    font-weight: 600;
  }
.container-center-horizontal {
  pointer-events: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  background-color: red;
  pointer-events: auto;
}

.screen a {
  text-decoration: none;
  display: contents;
  background-color: chartreuse;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: chartreuse;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.evento {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  padding: 0;
  position: relative;
}
/*banner*/
.buttonnormal{
  padding: 20px
}
/*idioma*/
.selector-idiomaEven {
  height: 30px;
  margin-top: 5px;
  margin-left: 80px;
}
.banderaIdiomaEven {
  height: 28px;
  object-fit: cover;
  width: 28px;
  margin-left: 20px;
}
.castellanoEven {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  letter-spacing: 0.00px;
  line-height: 60px;
  min-height: 32px;
  min-width: 132px;
  margin-top: -45px;
  text-align: center;
  white-space: nowrap;
}
.icon-ionic-md-arrow-dropdownEven {
  height: 9px;
  margin-bottom: 1.0px;
  margin-left: 170px;
  object-fit: cover;
  width: 18px;
  margin-top: -80px;
}
.imageBackGround{
  /* background-image:url("../../assets/Background_DreamingDreams2.png");
  background-size: cover; */
  background-color: black;
}
.resumenEven {
  -webkit-align-items: center;
          align-items: center;
  margin-inline: 20px;
  background-color: black;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: -webkit-max-content;
  height: max-content;
  position: relative;
  top: -50px;
  width: auto;
  max-width: 600px;
  min-width: 300px;
  box-shadow: -2px 0px 5px 0px #ffff
}
.text-4Even {
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
  font-size: 1.2rem;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  color: white;
  font-weight: bold;
}
.group-550Even {
  display: -webkit-flex;
  display: flex;
  margin: 20px;
}
.icon-metro-locationEven {
  height: 27px;
  margin-bottom: 0;
  object-fit: cover;
  width: 20px;
  margin-top: -10px;
}
.text-6Even {
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 10px;
  margin-top: -15px;
  font-size: 14px;
  text-align: center;
  color: white;
}
.text-5Even {
  font-size: 18px;
  margin-top: -10px;
  color: white;
}

.overlap-group1-2 {
  background-size: cover;
  height: 35px;
  border-radius: 5px;
  width: 200px;
}
.reservar-entradasEven {
  color: var(--cararra);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  letter-spacing: 0.54px;
  min-height: 24px;
  min-width: 174px;
  text-align: center;
  white-space: nowrap;
  color: white;
}

.overlap-groupEvento {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 295px;
  margin-left: 350px;
  padding: 71.0px 298px;
  width: auto;
}

.flex-rowEven {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 1153px;
  margin-top: -80px;
  min-width: 1320px;
}
.flex-colEven {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 80px;
  min-height: 1089px;
  position: relative;
  width: 580px;
}
.flex-row-1Even {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 32px;
  min-width: 437px;
}
.informacinEven {
  letter-spacing: 0;
  line-height: 60px;
  min-height: 32px;
  min-width: 159px;
  white-space: nowrap;
}
.reserva-entradas {
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 58px;
  min-height: 32px;
  white-space: nowrap;
}
.overlap-group3Even {
  background-color: var(--white);
  box-shadow: -6px -6px 6px #ffffff29;
  height: 180px;
  position: relative;
  width: 200px;
  
}
.musikoaz {
  color: var(--thunder);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxxl);
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: 116px;
  position: absolute;
  top: 34px;
  white-space: nowrap;
}

.bannerZaragoza{
  height: 500px;
  background-Repeat: no-repeat;
  background-Size: cover;
  background-position: center;
  background-position-y: 30%;
  width: inherit;
  margin: 0;
}
.eventoBanner{
  height: 500px;
  background-Repeat: no-repeat;
  background-Size: cover;
  background-position: center;
  width: inherit;
  margin: 0;
}
@media (max-width: 700px) {
  .bannerZaragoza{
    height: 330px;
    background-Repeat: no-repeat;
    background-Size: cover;
    background-position: center;
    width: inherit;
    margin-left: 15px;
  }
  .resumenEven {
    -webkit-align-items: center;
            align-items: center;
    margin-inline: 20px;
    background-color: black;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    top: -8px;
    width: auto;
    max-width: 600px;
    min-width: 300px;
    box-shadow: -2px 0px 5px 0px #ffff
  }
  .eventoBanner{
    height: 330px;
    background-Repeat: no-repeat;
    background-Size: cover;
    background-position: center;
    width: inherit;
    margin: 0;
  }
}





/*****HOME*****/
.home {
  -webkit-align-items: center;
          align-items: center;
  /* background-color: black; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  overflow: hidden;
  padding: 0px 0;
  /* width: auto; */
  background-image: url(/static/media/Background_DreamingDreams.8d6e341f.png);
  background-size: cover;
}

.fondoHome {
  background-color: rgba(145, 145, 145, 0.29);
  margin-left: -50px;
  margin-right: -50px;
}

.home2 {
  -webkit-align-items: center;
          align-items: center;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  overflow: hidden;
  padding: 0px 0;
  /* width: auto; */
  /*  background-image: url("./../assets/Background_DreamingDreams2.png"); */
}

.overlap-groupHome {
  height: 252px;
  position: relative;
  width: auto;
}

.bannerHome {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  /* height: 18rem; */
  min-width: 100vmax;
  /*   background-image: url("./../assets/Logo2.jpeg"); */
  -webkit-justify-content: center;
          justify-content: center;
  display: flex;
}

/*selector idioma*/
/* .selector-idiomaHome {
    height: 30px;
    margin-top: 10px;
  }
  .banderaIdiomaHome {
    height: 28px;
    object-fit: cover;
    width: 28px;
    margin-left: 0px;
  }
  .castellanoHome {
    align-self: flex-start;
    letter-spacing: 0.00px;
    line-height: 60px;
    min-height: 32px;
    min-width: 132px;
    margin-top: -45px;
    text-align: center;
    white-space: nowrap;
  }
  .icon-ionic-md-arrow-dropdown-2Home {
    height: 9px;
    margin-bottom: 1.0px;
    margin-left: 170px;
    object-fit: cover;
    width: 18px;
    margin-top: 0px;
  }

  
  /*selector*/
.selectorHome {
  background-color: var(--concrete);
  box-shadow: 6px 6px 18px #ffffff29;
  margin-top: 0px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.fila-Carta {
  padding: 0;
  box-shadow: 6px 6px 18px #00000029;
  background-color: white;
  width: 58%;
}

.flex-row-Home {}

.text-precioEve {
  letter-spacing: 0px;
  line-height: 40px;
  min-height: 29px;
  margin-left: 20px;
}

.flex-colHome {
  display: -webkit-flex;
  display: flex;
  min-height: 100px;
  -webkit-justify-content: center;
          justify-content: center;
}

.selecciona-el-cicloHome {
  color: black;
  font-family: var(--font-family-montserrat);
  padding: 22px;
  text-align: center;
  background-color: white;
  font-size: 50px;
  font-weight: 700;
}

#alpha {
  background-color: rgb(0, 0, 0, 0.9);
  width: 95%;
  -webkit-justify-content: center;
          justify-content: center;
}

#alpha_2 {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

/*calendario*/

/*****CALENDARIO*****/

.calendario-eventos {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  color: white;
  font-family: var(--font-family-montserrat);
  padding: 22px;
  text-align: center;
  /* background-color: black; */
  font-size: 42px;
  font-weight: 700;
}

.parrafoIndicacion {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.parrafoIndicacion2 {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  width: 100%;
  margin: 10px
}

/*****CARTA-EVENTO*****/
.carta-evento {
  width: 400px;
  margin-top: 50px;
  height: 450px;
  margin-bottom: 250px;
  margin-right: auto;
  margin-left: auto;
}

.overlap-groupCart {
  position: relative;
}

.cartaEve {
  border-radius: 10px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  height: 450px;
  left: 45px;
  box-shadow: 6px 6px 18px #00000029;
  width: 310px;
  position: absolute;
  top: 54px;
}

.cartaEveZaragosa {
  border-radius: 10px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  height: 360px;
  left: 72px;
  box-shadow: 6px 6px 18px #00000029;
  width: 250px;
  position: absolute;
  top: 45px;
}

.cartaEveVitoria {
  border-radius: 10px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  height: 330px;
  left: 45px;
  box-shadow: 6px 6px 18px #00000029;
  width: 310px;
  position: absolute;
  top: 74px;
}

.cartaEveMelodic {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  left: 45px;
  box-shadow: 6px 6px 18px #00000029;
  width: 310px;
  height: 320px;
  position: absolute;
  top: 80px;
}

.cartaEve2 {
  border-radius: 10px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  left: 45px;
  box-shadow: 6px 6px 18px #00000029;
  width: 310px;
  position: absolute;
  top: 400px;
}

.infoEve {
  box-shadow: -5px -5px 5px #ffffff29;
  background-color: var(--concrete);
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 510px;
  height: auto;
}

.nombreEvento {
  line-height: 40px;
  margin-left: 15px;
  margin-top: 8px;
  font-size: 20px;
}

.hora-1830Eve {
  letter-spacing: 0px;
  min-height: 29px;
  min-width: 119px;
  white-space: nowrap;
  margin-left: 20px;
  margin-top: 5px;
  font-size: 18px;
  /* font-family: var(--font-family-montserrat); */
}

.text-2Eve {
  letter-spacing: 0px;
  line-height: 35px;
  min-height: 29px;
  margin-left: 20px;
  font-size: 18px;
}

.fechaEve {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--concrete);
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  display: -webkit-flex;
  display: flex;
  height: 80px;
  -webkit-justify-content: center;
          justify-content: center;
  left: 45px;
  min-width: 50px;
  padding: 0 6px;
  position: absolute;
  top: 30px;
}

.fechaEveZaragoza {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--concrete);
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  display: -webkit-flex;
  display: flex;
  height: 80px;
  -webkit-justify-content: center;
          justify-content: center;
  left: 60px;
  min-width: 50px;
  padding: 0 6px;
  position: absolute;
  top: 30px;
}

.numberEve {
  font-size: 50px;
  color: black;
}

/*control de acceso*/
.containerPrincipal {
  position: absolute;
  left: 50%;
  margin-top: 150px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 40px;
  background-color: white;
}

.containerSecundario {
  text-align: center;
}

.containerIndicaHome {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  /* background-color: rgba(145, 145, 145, 0.29); */
  margin-top: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.imgBanner {
  width: 100%;
  height: 18rem;
  justify-Content: center;
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 700px) {
  .containerIndicaHome {
    margin-left: auto;
    margin-right: auto;
    width: 72%;
  }
  .imgBanner {
    width: 100%;
    height: 18rem;
    justify-Content: center;
    display: -webkit-flex;
    display: flex;
  }
  .cartaEveZaragosa {
    border-radius: 10px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    height: 398px;
    left: 45px;
    box-shadow: 6px 6px 18px #00000029;
    width: 306px;
    position: absolute;
    top: 5px;
  }
  .fechaEveZaragoza {
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--concrete);
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    display: -webkit-flex;
    display: flex;
    height: 80px;
    -webkit-justify-content: center;
            justify-content: center;
    left: 40px;
    min-width: 50px;
    padding: 0 6px;
    position: absolute;
    top: -13px;
  }
  #alpha {
    background-color: rgb(0, 0, 0, 0.9);
    width: 65%;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media (max-width: 400px) {
  .rowBanner{
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
  }
  .imgBanner {
    width: 80%;
    height: 12rem;
    justify-Content: center;
    display: -webkit-flex;
    display: flex;
  }
}
.abando {
  margin-left: 0px;
}

.overlap-group1 {
  background-color: black;
  box-shadow: 6px 6px 18px #00000029;
  height: 180px;
  position: relative;
  width: auto;
  border-radius: 10px;
  color: white;
  margin-top: 20px;
}
.ciclo-1 {
  left: 20px;
  letter-spacing: 0;
  line-height: 60px;
  position: relative;
  top: 8px;
  white-space: nowrap;
  font-weight: bold;

}
.concierto {
  margin-right: 15px;
}
.contenido {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 150px;
}
.descripcin {
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
}
.descripcin-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 1008px;
  width: 722px;
}
.flex-col {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
}
.flex-col-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin-top: 120px;
}
.la-cantante-y-compos {
  color: #707070;
  font-family: var(--font-family-montserrat);
  font-weight: 300;
  line-height: 40px;
  width: auto;
  margin-left: 5px;
  color: white;
}
.tags-1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 6px 6px 18px #00000029;
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
  width: 380px;
}
.overlap-groupInfo {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--white);
  box-shadow: -6px -6px 6px #ffffff29;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  height: 180px;
  width: 380px;
  padding: 8px 20px;
}
.overlap-group1-1Even {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-self: center;
          align-self: center;
  background-position: 50% 50%;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  height: 227px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 41px;
  min-width: 460px;
  padding: 89.1px 213px;
}

.pop {
  margin-left: 24px;
}
.tags {
  letter-spacing: 0;
  line-height: 60px;
  min-height: 32px;
  white-space: nowrap;
  margin-top: auto;
  margin-right: auto;
  color: white;
}

.title {
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxxl);
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: 40px;
  position: relative;
  white-space: normal;
  color: white;
}
.text-3Even {
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 50px;
  width: 238px;
  font-size: 1.2rem;
  color: white;
}
.text-DirEven {
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 15px;
  line-height: 2;
  width: 287px;
  color: white;
}
.ubicacionEven {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: black;
  border-radius: 10px;
  box-shadow: 6px 6px 18px #00000029;
  display: -webkit-flex;
  display: flex;
  height: -webkit-max-content;
  height: max-content;
  /* margin-top: 60px; */
  width: 100%;
  color: white;
  font-size: 13.5PX;
  font-family: var(--font-family-montserrat);
}
.overlap-group4Even {
  
}

.ubicacinEven {
  letter-spacing: 0;
  line-height: 60px;
  margin-bottom: -28px;
  margin-left: 20px;
  min-height: 32px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.25rem;
}
.icon-material-location-onEven {
  height: 33px;
  object-fit: cover;
  width: 21px;
}
.texto-info{
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 60px;
}

.fondoMap{
  background-color:red;
  margin-top:20px;
  height:310px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#map{
  height: 280px;
  width: 440px;
  top: 15px;
  margin-left:10px;
  margin-right:10px;
}
.colmDer{
  margin-left: 50px;
}

.backgroundGallery{
  margin-top: 35px;
  width: 460px;
}

.buttonnormal{
  padding: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

@media  (max-width:700px) {
  
  #map{
    width: 320px;
  }
  .colmDer{
    margin-left: 0px;
  }
  .backgroundGallery{
    width: 320px;
  }
  .buttonnormal{
    padding: 5px;
  }
}


.acompaante-1 {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -36px;
    margin-top: 43px;
    min-height: 24px;
    white-space: nowrap;
  }
  .aviso-legal {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 76px;
    margin-top: 1.0px;
    min-height: 24px;
    min-width: 105px;
    white-space: nowrap;
  }
 
  .buttonnormalRes {
    min-width: auto;
    position: absolute;
    margin-top: inherit;
    border-radius: 20px;
  }
  .buttonnQrDowloand{
    border-radius: 10px;
    width: 200px;
    margin-top: 20px;
  }
  .info-evento {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    display: -webkit-flex;
    display: flex;
    margin-top: 35px;
    min-height: 188px;
  }
  .span1{
    margin: 0px;
    color: white;
  }
  .rectangle-1620 {
    background-color: black;
    border-radius: 10px;
    -webkit-align-items: center;
            align-items: center;
    height: 47px;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .entrada-1-de-2 {
    line-height: 50px;
    text-align: center;
    color: white;
  }
  .image-4 {
    object-fit: cover;
  }
  .texto-Confirmacion{
    margin-left: 40px;
    margin-top: 30px;
    width: 100%;
    margin-right: 30px;
    color: white;
  }
  .rectangle-1619 {
    background-color: black;
    border-radius: 10px;
    box-shadow: 6px 6px 18px #00000029;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 15px;
    height: 200px;
    margin-top: 40px;
  }
  .overlap-group1-2Res{
    background-size: cover;
    height: 35px;
    border-radius: 5px;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
  }
  .entrada {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: -webkit-max-content;
    height: max-content;
  }

  .overlap-group-entrada {
    height: 364px;
    position: relative;
    width: 400px;
    margin-top: 25px;
    
    -webkit-align-items: center;
    
            align-items: center;
  }

  .text-enhorabuena {
    color: var(--thunder);
    font-family: var(--font-family-montserrat);
    font-weight: 500;
    letter-spacing: 0.78px;
    line-height: 45px;
    margin-right: 8.0px;
    margin-top: 25px;
    min-height: -webkit-max-content;
    min-height: max-content;
    word-wrap: normal;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    text-align: center;
    white-space: normal;
    color: white;
  }
  .buttonnormal-1 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    margin-right: 58.0px;
    margin-top: 61px;
    min-width: 266px;
  }
  .cancelar {
    left: 109px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
  }
  .checkbox-circle {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 9.0px;
    min-width: 21px;
  }
  .component-26-5 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 35px;
    -webkit-justify-content: center;
            justify-content: center;
    left: 109px;
    min-width: 233px;
    position: absolute;
    top: 865px;
  }
  .confirmacin {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 185px;
    min-height: 24px;
    white-space: nowrap;
  }
  .confirmar-correo {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 76px;
    min-height: 59px;
    position: relative;
    width: 315px;
    color:white;
  }
  .correo {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 59px;
    position: relative;
    width: 315px;
    color:white;
  }
  .correo-electronico {
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: -4px;
    margin-top: 5px;
    min-height: 21px;
    white-space: nowrap;
    color:white;
  }
  .datos {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 160px;
    min-height: 24px;
    min-width: 55px;
    white-space: nowrap;
  }
  .datos-acompaantes {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -36px;
    min-height: 24px;
    white-space: nowrap;
    color: white;
  }
  .datos-acompanante {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 177px;
    margin-left: 32px;
    margin-top: 52px;
    min-width: 580px;
    position: relative;
  }
  .dropdown {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 38px;
    min-width: 62px;
  }

  .dropdown-entradas {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 38px;
    min-width: 62px;
    position: absolute;
  }
  .ellipse-1 {
    border-radius: 13px;
    height: 26px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 26px;
  }
  .ellipse-2 {
    background-color: black;
    border-radius: 12px;
    height: 24px;
    left: 276px;
    position: absolute;
    top: 1px;
    width: 24px;
  }
  .ellipse-3 {
    background-color:black;
    border-radius: 12px;
    height: 24px;
    left: 552px;
    position: absolute;
    top: 1px;
    width: 24px;
  }
  .ellipse-412 {
    background-color: black;
    border-radius: 9.5px;
    height: 19px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 19px;
  }
  .fila-entrada{
    margin-left: auto;
  }
  .container-info{
  border-radius:10px;
  box-shadow: 6px 6px 18px #00000029;
  height: -webkit-max-content;
  height: max-content;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  margin-top: 40px;
  }
  .container-info2{
    border-radius:10px;
    box-shadow: 6px 6px 18px #00000029;
    height: -webkit-max-content;
    height: max-content;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: wrap;
            flex-flow: wrap;
    margin: 20px;
    padding: 15px;
    }
  .entrada-general {
    letter-spacing: 0;
    position: absolute;
    font-size: 1.10rem;
    color: white;
  }
  .Horaentrada-general{
    letter-spacing: 0;
    position: absolute;
    font-size: 1.10rem;
    font-weight: 400;
    color: white;
  }
  .entrada-general-1 {
    letter-spacing: 0;
    min-height: 24px;
    color: white;
  }
  .evento-2 {
    -webkit-align-items: center;
            align-items: center;
    background-color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 2613px;
    overflow: hidden;
    padding: 54px 0;
    width: 1920px;
  }
  .flex-colRes {
    margin-left: 15px;
    width: 62px;
  }
  .flex-col-1Res {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: -15px;
    min-height: 354px;
    width: 148px;
  }
  .flex-col-2 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 129px;
    width: 200px;
  }
  .flex-col-3 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: -19px;
    min-height: 196px;
    width: 250px;
  }

  .flex-row {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    display: -webkit-flex;
    display: flex;
    height: 129px;
    margin-top: -19px;
    min-width: 706px;
    position: relative;
  }
  .flex-row-1 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 57px;
    min-width: 1322px;
  }
  .flex-row-2 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 59px;
    margin-top: 52px;
    min-width: 706px;
  }
  .flex-row-3Res {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: 24px;
    margin-top: 2px;
    min-width: 702px;
  }
  .fondo {
    height: 678px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1923px;
  }
  .footer {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    height: 300px;
    margin-top: 318px;
    min-width: 1920px;
    padding: 1.3px 0;
  }
  .group-555 {
    -webkit-align-items: center;
            align-items: center;
    display: block;
    margin-top: auto;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
  }
  .group-5552 {
    margin-top: 30px;
    width: 100%;
  }
  .icon-feather-check {
    height: 8px;
    left: 4px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 13px;
  }
  .icon-ionic-md-arrow-dropdown {
    height: 9px;
    left: 39px;
    object-fit: cover;
    position: absolute;
    top: 26px;
    width: 18px;
  }
  .info-entrada {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
  }
  .informacin {
    left: 1040px;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 728px;
    white-space: nowrap;
  }
  .line-1 {
    height: 3px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 11px;
    width: 554px;
  }
  .line-2 {
    height: 1px;
    object-fit: cover;
    width: 15px;
  }
  .line-3 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 15px;
  }
  .line-4 {
    height: 15px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .logo-ontourup-negro {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    height: 53px;
    object-fit: cover;
    width: 117px;
  }
  .nombre {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 46px;
    min-height: 59px;
    position: relative;
    width: 250px;
    color:white;
  }
  .nombre-1 {
    letter-spacing: 0;
    line-height: 20px;
    min-height: 21px;
    white-space: nowrap;
    margin-top: 5px;
    width: 250px;
    color:white;
  }
  .nombre-2 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 46px;
    min-height: 59px;
    position: relative;
    width: 200px;
  }
  .number {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 14px;
    margin-top: -20.5px;
    min-height: 21px;
    min-width: 11px;
    white-space: nowrap;
  }
  .number-1 {
    left: 15px;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  .numero-telefono {
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: -1px;
    margin-top: -1px;
    min-height: 21px;
    white-space: nowrap;
  }
  .overlap-group {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 295px;
    padding: 71.0px 298px;
    width: 1920px;
  }
  .overlap-group-1Res {
    height: 26px;
    position: relative;
    width: 576px;
  }
  .overlap-group-2 {
    height: 49px;
    position: relative;
  }
  .overlap-group-3 {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    height: 41px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    min-width: 266px;
    padding: 5.5px 45px;
  }
  .texto-Reserva{
    margin-top: 80px;
    font-size: 20px;
  }
  .texto-entrada{
    text-align: center;
    color: white;
  }
  .texto-Error{
    text-align: center;
    margin-top: 35px;
    color: tomato;
  }
  
  .overlap-group-4 {
    height: auto;
    position: relative;
    width: 100%;
    background-color: black;
  }
  .overlap-group1Res {
    width: 100%;
    }
  .overlap-group2 {
    height: 99px;
    margin-top: -33px;
    position: relative;
    width: 299px;
  }
  .overlap-group2-1 {
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10.5px;
    height: 21px;
    position: relative;
    width: 21px;
  }
  .overlap-group2-2 {
    height: 15px;
    margin-bottom: 1.0px;
    margin-left: 12px;
    position: relative;
    width: 15px;
  }
  .overlap-group3Res {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    height: 35px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    min-width: 233px;
    padding: 2.5px 69.5px;
  }
  .Seleccionar-Sesion{
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    height: 35px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .overlap-group4 {
    height: 246px;
    position: relative;
    width: 726px;
    
  }
  .overlap-group5 {
    height: 773px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1923px;
  }
  .overlap-group6 {
    height: 773px;
    margin-left: 0;
    margin-top: 29px;
    position: relative;
    width: 1923px;
  }
  .pago {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background-color: black;
    border-radius: 10px;
    box-shadow: 6px 6px 18px #00000029;
    margin-top: 40px;
    margin-left: auto;
    margin-bottom: 150px;
    margin-right: auto;
    width: 100%;
    max-width: 850px;
  }
  .path-3434 {
    height: 234px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 814px;
  }
  .path-3435 {
    height: 234px;
    left: 32px;
    object-fit: cover;
    position: absolute;
    top: 12px;
    width: 794px;
  }
  .persona-contacto {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 50.0px;
    margin-top: 55px;
    min-height: 221px;
    width: 706px;
    color:white;
  }
  .persona-de-contacto {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -36px;
    min-height: 24px;
    white-space: nowrap;
    color:white;
  }
  .place {
    letter-spacing: 0;
    position: absolute;
    white-space: nowrap;
    font-weight: 400;
    color: white;
  }
  .poltica-de-cookies {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 75px;
    min-height: 24px;
    min-width: 174px;
    white-space: nowrap;
  }
 
  .rectangle-1498 {
    background-color: black;
    border-radius: 10px;
    height: 33px;
    left: 34px;
    position: absolute;
    top: 34px;
    width: 231px;
  }
  .rectangle-1603 {
    border-radius: 10px;
    height: 87px;
    left: 12px;
    object-fit: cover;
    position: absolute;
    top: 12px;
    width: 287px;
  }
  .rectangle-1604 {
    border-radius: 10px;
    height: 89px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 287px;
  }
  .rectangle-168 {
    height: 950px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 445px;
  }
  .rectangle-172 {
    border-radius: 10px;
    height: 38px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 11px;
    width: 62px;
  }
  .rectangle-173 {
    background-color: black;
    border-radius: 10px;
    height: 36px;
    left: 1px;
    position: absolute;
    top: 12px;
    width: 60px;
  }
  .reserva-entradas {
    left: 1257px;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 728px;
    white-space: nowrap;
  }
  .reservar-entradas {
    color: var(--cararra);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs2);
    letter-spacing: 0.54px;
    line-height: 35px;
    min-height: 24px;
    min-width: 174px;
    text-align: center;
    white-space: nowrap;
    color: white;
  }
  .resumenRes {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 40px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    position: absolute;
    top: 15px;
  }
  .resumen-1 {
    -webkit-backdrop-filter: blur(30px) brightness(115%);
    -webkit-align-items: center;
            align-items: center;
    backdrop-filter: blur(30px) brightness(115%);
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    left: 238px;
    min-height: 375px;
    padding: 10px 20px;
    position: absolute;
    top: 398px;
    width: 680px;
  }
  .resumen-2 {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -28px;
    min-height: 32px;
    white-space: nowrap;
  }
  .seleccin-entradas {
    letter-spacing: 0;
    line-height: 60px;
    min-height: 24px;
    min-width: 176px;
    white-space: nowrap;
  }
  .selector {
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
    display: -webkit-flex;
    display: flex;
    height: 19px;
    margin-right: 3.0px;
    margin-top: 29px;
    min-width: 68px;
  }
  .side-bar {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background-position: 50% 50%;
    background-size: auto;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 5px;
    margin-top: 90px;
  }
  .siguiente {
    letter-spacing: 0.54px;
    line-height: 18px;
    min-height: 24px;
    text-align: center;
    white-space: nowrap;
    font-size: 20px;
  }
  .span-1 {
    text-decoration: underline;
  }
  .stepper {
    width: 100%;
  }
  .steps {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    background-color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 115px;
    padding: 24px 58px;
    width: 820px;
  }
  .subtotal {
    letter-spacing: 0;
    white-space: nowrap;
  }
  .telefono {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1.0px;
    margin-left: 31px;
    min-height: 59px;
    position: relative;
    width: 190px;
  }
  .text-1 {
    letter-spacing: 0;
    line-height: 60px;
    margin-left: 298px;
    margin-top: 1.0px;
    min-height: 24px;
    min-width: 199px;
    white-space: nowrap;
  }
  .text-13 {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    letter-spacing: 0;
    line-height: 60px;
    min-height: 111px;
    min-width: 584px;
    text-align: center;
    width: 584px;
  }
  .text-14 {
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: -21px;
    margin-right: 55.0px;
    margin-top: 12px;
    min-height: 39px;
    min-width: 305px;
    white-space: nowrap;
  }
  .text-2 {
    letter-spacing: 0;
    margin-top: 15px;
    min-height: 24px;
    white-space: nowrap;
    color: white;
  }
  .text-3 {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    height: 52px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: -2px;
    margin-left: 7px;
    min-height: 52px;
    min-width: 324px;
    width: 324px;
  }
  .text-4Res {
    letter-spacing: 0;
    position: absolute;
    margin-top: 110px;
    margin-left:30px;
    white-space: nowrap;
  }
  .text-9 {
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: -4px;
    margin-left: 0.43px;
    margin-top: -3px;
    min-height: 21px;
    white-space: nowrap;
  }
  .total {
    letter-spacing: 0;
    white-space: nowrap;
  }
.selector-idioma {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .botonIdioma{
      margin-left: 20px;
      background-color: transparent;
      -webkit-align-items: center;
              align-items: center;
      margin-top: 5px;
  }
  .mask-group-1 {
    height: 28px;
    object-fit: cover;
    width: 28px;
    margin-left: -60px;
    
  }
  .banderaCastellano{
    height: 28px;
    object-fit: cover;
    width: 28px;
    margin-left: -180px;
  }
  .castellanoBoton{
    -webkit-align-self: flex-start;
            align-self: flex-start;
    letter-spacing: 0.00px;
    line-height: 60px;
    margin-left: -10px;
    margin-top: -45px;
    min-height: 32px;
    min-width: 100px;
    text-align: justify;
    white-space: nowrap;
  }
  .castellano {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    letter-spacing: 0.00px;
    line-height: 60px;
    margin-left: -20px;
    margin-top: -45px;
    min-height: 32px;
    min-width: 100px;
    text-align: justify;
    white-space: nowrap;
    
  }

