.evento {
  align-items: flex-start;
  background-color: black;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  position: relative;
}
/*banner*/
.buttonnormal{
  padding: 20px
}
/*idioma*/
.selector-idiomaEven {
  height: 30px;
  margin-top: 5px;
  margin-left: 80px;
}
.banderaIdiomaEven {
  height: 28px;
  object-fit: cover;
  width: 28px;
  margin-left: 20px;
}
.castellanoEven {
  align-self: flex-start;
  letter-spacing: 0.00px;
  line-height: 60px;
  min-height: 32px;
  min-width: 132px;
  margin-top: -45px;
  text-align: center;
  white-space: nowrap;
}
.icon-ionic-md-arrow-dropdownEven {
  height: 9px;
  margin-bottom: 1.0px;
  margin-left: 170px;
  object-fit: cover;
  width: 18px;
  margin-top: -80px;
}
.imageBackGround{
  /* background-image:url("../../assets/Background_DreamingDreams2.png");
  background-size: cover; */
  background-color: black;
}
.resumenEven {
  align-items: center;
  margin-inline: 20px;
  background-color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: max-content;
  position: relative;
  top: -50px;
  width: auto;
  max-width: 600px;
  min-width: 300px;
  box-shadow: -2px 0px 5px 0px #ffff
}
.text-4Even {
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
  font-size: 1.2rem;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  color: white;
  font-weight: bold;
}
.group-550Even {
  display: flex;
  margin: 20px;
}
.icon-metro-locationEven {
  height: 27px;
  margin-bottom: 0;
  object-fit: cover;
  width: 20px;
  margin-top: -10px;
}
.text-6Even {
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 10px;
  margin-top: -15px;
  font-size: 14px;
  text-align: center;
  color: white;
}
.text-5Even {
  font-size: 18px;
  margin-top: -10px;
  color: white;
}

.overlap-group1-2 {
  background-size: cover;
  height: 35px;
  border-radius: 5px;
  width: 200px;
}
.reservar-entradasEven {
  color: var(--cararra);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  letter-spacing: 0.54px;
  min-height: 24px;
  min-width: 174px;
  text-align: center;
  white-space: nowrap;
  color: white;
}

.overlap-groupEvento {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 295px;
  margin-left: 350px;
  padding: 71.0px 298px;
  width: auto;
}

.flex-rowEven {
  align-items: flex-start;
  display: flex;
  height: 1153px;
  margin-top: -80px;
  min-width: 1320px;
}
.flex-colEven {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  min-height: 1089px;
  position: relative;
  width: 580px;
}
.flex-row-1Even {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  height: 32px;
  min-width: 437px;
}
.informacinEven {
  letter-spacing: 0;
  line-height: 60px;
  min-height: 32px;
  min-width: 159px;
  white-space: nowrap;
}
.reserva-entradas {
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 58px;
  min-height: 32px;
  white-space: nowrap;
}
.overlap-group3Even {
  background-color: var(--white);
  box-shadow: -6px -6px 6px #ffffff29;
  height: 180px;
  position: relative;
  width: 200px;
  
}
.musikoaz {
  color: var(--thunder);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxxl);
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: 116px;
  position: absolute;
  top: 34px;
  white-space: nowrap;
}

.bannerZaragoza{
  height: 500px;
  background-Repeat: no-repeat;
  background-Size: cover;
  background-position: center;
  background-position-y: 30%;
  width: inherit;
  margin: 0;
}
.eventoBanner{
  height: 500px;
  background-Repeat: no-repeat;
  background-Size: cover;
  background-position: center;
  width: inherit;
  margin: 0;
}
@media (max-width: 700px) {
  .bannerZaragoza{
    height: 330px;
    background-Repeat: no-repeat;
    background-Size: cover;
    background-position: center;
    width: inherit;
    margin-left: 15px;
  }
  .resumenEven {
    align-items: center;
    margin-inline: 20px;
    background-color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: max-content;
    position: relative;
    top: -8px;
    width: auto;
    max-width: 600px;
    min-width: 300px;
    box-shadow: -2px 0px 5px 0px #ffff
  }
  .eventoBanner{
    height: 330px;
    background-Repeat: no-repeat;
    background-Size: cover;
    background-position: center;
    width: inherit;
    margin: 0;
  }
}




