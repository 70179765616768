@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,300,500,600");

.container-center-horizontal {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: red;
  pointer-events: auto;
}

.screen a {
  text-decoration: none;
  display: contents;
  background-color: chartreuse;
}